import { graphql, PageProps } from "gatsby";
import * as React from "react";
import { Layout } from "../components/Layout";
import { layout } from "../constants";
import parse from "html-react-parser";
import { SaleInfo } from "../components/BuyDialog";
import { Button } from "../components/Button";

export const PreviewChapter: React.VFC<
  PageProps<{ wpPage: { saleInfo: SaleInfo } }>
> = (props) => {
  const { saleInfo } = props.data.wpPage;
  return (
    <Layout headerVariation="small" title="Bedankt!" hideFooter>
      <div
        className={`prose sm:prose-lg md:prose-xl mx-auto ${layout.ySpacing} text-center`}
      >
        <h1>Bedankt voor uw aankoop!</h1>
        {parse(saleInfo.thankyoumessage)}

        <div className="mt-20">
          <Button to="/">Terug naar de website</Button>
        </div>
      </div>
    </Layout>
  );
};

export default PreviewChapter;

export const pageQuery = graphql`
  query ThankyouPageQuery {
    wpPage(id: { eq: "cG9zdDoxNDc=" }) {
      saleInfo {
        notforsalemessage
        thankyoumessage
        currentlyforsale
      }
    }
  }
`;
